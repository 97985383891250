<template>
  <div class="list-board-style">
    <Loading v-if="is_loading" />
    <content-not-view v-else-if="!subPermission.index" />
    <b-card v-else>
      <div class="custom-search d-md-flex justify-content-between mb-1">
        <!-- search input -->
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">{{ $t("g.searchLabel") }}</label>
            <b-form-input
              :placeholder="$t('g.searchHere')"
              type="text"
              class="d-inline-block"
              v-model="searchTerm"
            />
          </div>
        </b-form-group>

        <div v-if="subPermission.store">
          <b-button :to="{ name: 'addBoardStyle' }" variant="primary" class="w-100">
            {{ $t("g.boardStyles/add_board_style") }}
          </b-button>
        </div>
      </div>

      <!-- table -->
      <vue-good-table
        styleClass="vgt-table striped"
        :columns="columns"
        :rows="rows"
        :search-options="{ enabled: true, externalQuery: searchTerm }"
        :sort-options="{ enabled: false }"
        :select-options="{ enabled: true, selectAllByGroup: true }"
        :pagination-options="{ enabled: true, perPage: pageLength }"
      >
        <template slot="table-column" slot-scope="props">
          <!-- Column: Name -->
          <span v-if="props.column.label === 'name'" class="text-nowrap">
            {{ $t("g.boardStyles/board_style_name") }}
          </span>

          <span
            v-if="props.column.label === 'primary_color'"
            class="text-nowrap"
          >
            {{ $t("g.boardStyles/primary_color") }}
          </span>
          <span
            v-if="props.column.label === 'secondary_color'"
            class="text-nowrap"
          >
            {{ $t("g.boardStyles/secondary_color") }}
          </span>
          <span
            v-if="props.column.label === 'is_available'"
            class="text-nowrap"
          >
            {{ $t("g.boardStyles/is_available") }}
          </span>
          <span v-if="props.column.label === 'actions'" class="text-nowrap">
            {{ $t("g.boardStyles/actions") }}
          </span>
        </template>

        <!-- Slot: Table Row -->
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'name'" class="text-nowrap">
            {{ props.row ? props.row.name : "Name" }}
          </span>

          <span
            v-if="props.column.field === 'primary_color'"
            class="rounded shadow-lg text-nowrap color-cube"
            :style="{ backgroundColor: props.row.primary_color }"
          >
          </span>
          <span
            v-if="props.column.field === 'secondary_color'"
            class="rounded shadow-lg text-nowrap color-cube"
            :style="{ backgroundColor: props.row.secondary_color }"
          >
          </span>
          <b-badge
            v-if="props.column.field === 'is_available'"
            :variant="props.row.is_available ? 'success' : 'danger'"
            class="text-nowrap"
          >
            {{ props.row ? props.row.is_available : null }}
          </b-badge>
          <span v-if="props.column.field === 'actions'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
                v-if="subPermission.update || subPermission.destroy"
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>

                <b-dropdown-item
                  v-if="subPermission.update"
                  :to="{ name: 'editBoardStyle', params: { id: props.row.id } }"
                >
                  <feather-icon icon="EditIcon" class="mr-50" />
                  <span>{{ $t("g.edit") }}</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="subPermission.destroy"
                  @click="deleteAlert(props.row.id)"
                >
                  <feather-icon icon="Trash2Icon" class="mr-50" />
                  <span>{{ $t("g.delete") }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>
        </template>

        <template slot="emptystate">
          <span class="text-center" style="width: 100%; display: inline-block;">
            {{ $t("g.no_data_for_table") }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                {{ $t("g.pagelength") }}
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['3', '5', '10']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap">
                {{ $t("g.of") }} {{ props.total }}
                {{ $t("g.pageText") }}
              </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </div>
</template>

<script>
import { VueGoodTable } from "vue-good-table";
import {
  BBadge,
  BButton,
  BCard,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BPagination,
} from "bootstrap-vue";
import Loading from "@/components/loading/loading.vue";
import contentNotView from "@/components/contentNotView/contentNotView.vue";
export default {
  name: "BoardStylesList",
  components: {
    Loading,
    VueGoodTable,
    BPagination,
    BBadge,
    BCard,
    BDropdownItem,
    BDropdown,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    contentNotView,
  },
  data() {
    return {
      is_loading: true,
      searchTerm: "",
      pageLength: 10,
      rows: [],
      columns: [
        {
          label: "name",
          field: "name",
        },
        {
          label: "primary_color",
          field: "primary_color",
        },
        {
          label: "secondary_color",
          field: "secondary_color",
        },
        {
          label: "is_available",
          field: "is_available",
        },
        {
          label: "actions",
          field: "actions",
        },
      ],
    };
  },
  computed: {
    subPermission() {
      this.$store.dispatch("GET_PERMISSION", "board-styles");
      return this.$store.state.userData.sub_Permission;
    },
  },
  mounted() {
    this.getBoardStyles();
  },

  methods: {
    deleteAlert(id) {
      this.$helpers.sweetAlertConfirm(
        this.$t("g.are_you_sure"),
        this.$t("g.you_wont_be_able_to_revert_this"),
        "warning",
        this.$t("g.yes_delete_it"),
        this.$t("g.cancel"),
        () => this.deleteBoardStyle(id)
      );
    },

    getBoardStyles() {
      this.$http
        .get("admin/board-styles")
        .then((response) => {
          this.rows = response.data.data;
          this.is_loading = false;
        })
        .catch((err) => {
          this.$helpers.handleError(err);
          this.is_loading = false;
        }).finally;
    },
    deleteBoardStyle(id) {
      this.$http
        .delete(`admin/board-styles/${id}`)
        .then((response) => {
          this.getBoardStyles();
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
  },
};
</script>

<style lang="scss" src="./_index.scss" />
